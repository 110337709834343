<template>
  <center v-if="isbanned == true">
    <h2 class="mb-3 bannedMessage">
      CHANNEL BANNED<br> <div style="color: #ffff !important; margin-top: 10px;">
        {{ channel_name.toUpperCase() }}
      </div>
    </h2>
    <div class="details">
      <h4>Ban Date:</h4>
      <p>{{ bandata.ban_date }}</p>
      <h4>Ban Type:</h4>
      <p class="mb-1">
        {{ bandata.ban_type }}
      </p>
      <h4>Ban Reason:</h4>
      <p class="mb-1">
        {{ bandata.ban_reason }}
      </p>
      <h4>Additional Information:</h4>
      <p class="mb-1">
        {{ bandata.ban_message }}
      </p>
    </div>
  </center>
</template>

<script>
export default {
  data() {
    return {
      bandata: {
        ban_type: '',
        ban_reason: '',
        ban_additional_info: '',
      },
      channel_name: '',
      isbanned: false,
    }
  },
  async created() {
    const url = window.location.pathname.substring(1).toLowerCase()
    const slug = url.split('/')[1]

    const channelLookup = await this.channelLookupBySlug(slug)
    this.channel_name = channelLookup.channel.slug
    this.bandata = await this.banCheck(channelLookup.channel.xid)
    if (this.bandata.error) {
      this.$router.push(`/${slug}`)
    } else {
      this.isbanned = true
    }
    this.bandata.ban_date = new Date(this.bandata.ban_date * 1000).toLocaleString()
  },
  beforeUpdate(){
    document.body.classList.remove('channel__page')
    clearInterval( this.viewCounter )
  }
}
</script>

<style>
.bannedMessage {
color: #ea5455 !important;
}
.details {
text-align: center;
}
</style>
